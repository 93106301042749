<template>
  <div class="statistic">
    <h2>Статистика</h2>
    <form class="seacrh-form" @submit.prevent="getStatistic">
      <div>
        <div class="form-group">
          <div class="input-wrapper">
            <label class="label" for="data-for">Дата от</label>
            <input class="input" type="date" id="data-for" v-model="datefrom" />
          </div>
          <div class="input-wrapper">
            <label class="label" for="data-to">Дата до</label>
            <input class="input" type="date" id="data-to" v-model="dateto" />
          </div>
        </div>
        <div class="form-group">
          <div class="input-wrapper">
            <label class="label" for="projects">Проекты</label>
            <select
              id="projects"
              v-model="project"
              ref="selectProject"
              class="select-wrapper"
            >
              <option value="" disabled selected>Выберите проект</option>
              <option value="all">Все проекты</option>
              <option v-for="{ pk, fields } of projects" :key="pk" :value="pk">
                {{ fields.title }}
              </option>
            </select>
          </div>
          <div class="input-wrapper">
            <label class="label" for="partners">Партнеры</label>
            <select
              id="partners"
              v-model="partner"
              ref="selectPartners"
              class="select-wrapper"
            >
              <option value="" disabled selected>Выберите партнера</option>
              <option value="all">Все партнеры</option>
              <option v-for="{ pk, fields } of partners" :key="pk" :value="pk">
                {{ fields.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <button class="btn">Отправить</button>
    </form>
  </div>
</template>

<script>
import dayjs from "dayjs";
import API from "../../tools/API";

export default {
  name: "Statistic",
  data() {
    return {
      api: new API(),
      datefrom: dayjs().format("YYYY-MM-DD"),
      dateto: dayjs().format("YYYY-MM-DD"),
      project: "all",
      partner: "all",
      projects: [],
      partners: [],
      selectProject: null,
      selectPartners: null
    };
  },
  methods: {
    async getStatistic() {
      this.$store.commit("toggleLoad", true);

      const formData = {
        project: this.project,
        partner: this.partner,
        datefrom: this.datefrom,
        dateto: this.dateto
      };

      try {
        M.toast({
          html: `[Запрос на получение статистики успешно отправлен!]`,
          classes: "teal darken-1",
          displayLength: 10000
        });
        const resp = await this.api.getStatistic(formData);
        const statistic = resp.result.clicks;
        this.$store.commit("setStatistic", statistic);
        if (resp) {
          console.log(resp);
        }
      } catch (error) {
        console.log(error);
      }
      this.$store.commit("toggleLoad", false);
    },
    async getReferences() {
      try {
        const resp = await this.api.getReferences();
        if (resp.result) {
          const results = resp.result;
          this.projects = results.projects;
          this.partners = results.partners;
          this.$store.commit("toggleLoad", false);
        }
      } catch (error) {
        console.log(error);
        this.$store.commit("toggleLoad", false);
      }
    }
  },
  created() {
    this.$store.commit("toggleLoad", true);
    this.getReferences();
  },
  mounted() {
    /* global M */
    this.selectProject = M.FormSelect.init(this.$refs.selectProject, {});
    this.selectPartners = M.FormSelect.init(this.$refs.selectPartners, {});
  },
  updated() {
    if (this.selectProject && this.selectProject.destroy) {
      this.selectProject.destroy();
    }

    if (this.selectPartners && this.selectPartners.destroy) {
      this.selectPartners.destroy();
    }

    M.updateTextFields();
    this.selectProject = M.FormSelect.init(this.$refs.selectProject, {});
    this.selectPartners = M.FormSelect.init(this.$refs.selectPartners, {});
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

.seacrh-form {
  border: 1px solid #4285f4;
  border-radius: 30px;
  padding: 20px;
  margin-bottom: 50px;

  .btn {
    width: 100%;
    margin-top: 20px;
    background-color: #4285f4;
  }
}

.form-group {
  display: flex;
  justify-content: space-between;

  .input-wrapper {
    display: flex;
    flex-direction: column;

    width: calc(50% - 20px);
  }

  .label {
    @extend .label;
  }
}
</style>
