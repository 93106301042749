<template>
  <div class="result">
    <table>
      <tr class="table-header">
        <th class="col">Партнер</th>
        <th class="col">Клики</th>
        <th class="col">Регистрация</th>
        <th class="col">Выдачи</th>
        <th class="col">AR%</th>
        <th class="col">EPC</th>
        <th class="col">Ликвидность</th>
        <th class="col">ОТЧЕТЫ</th>
      </tr>
      <tr v-for="result in $store.state.statistic" :key="result.id">
        <td class="col">{{ result.partner }}</td>
        <td class="col">{{ result.clicks }}</td>
        <td class="col">{{ result.regs }}</td>
        <td class="col">{{ result.issues }}</td>
        <td class="col">{{ result.ar }}</td>
        <td class="col">{{ result.epc }}</td>
        <td class="col">{{ result.money }}</td>
        <td class="col">
          <a :href="result.report" target="_blank">Выгрузка</a>
        </td>
      </tr>
      <tr>
        <td class="col">Итого:</td>
        <td class="col">
          {{
            $store.state.statistic.reduce((acc, i) => {
              return (acc += Number(i.clicks));
            }, 0)
          }}
        </td>
        <td class="col">
          {{
            $store.state.statistic.reduce((acc, i) => {
              return (acc += Number(i.regs));
            }, 0)
          }}
        </td>
        <td class="col">
          {{
            $store.state.statistic.reduce((acc, i) => {
              return (acc += Number(i.issues));
            }, 0)
          }}
        </td>
        <td class="col">
          {{
            $store.state.statistic.reduce((acc, i) => {
              return (acc += Number(i.ar));
            }, 0) / $store.state.statistic.length
          }}
        </td>
        <td class="col">
          {{
            $store.state.statistic.reduce((acc, i) => {
              return (acc += Number(i.epc));
            }, 0) / $store.state.statistic.length
          }}
        </td>
        <td class="col">
          {{
            $store.state.statistic.reduce((acc, i) => {
              return (acc += Number(i.money));
            }, 0)
          }}
        </td>
        <td class="col"></td>
      </tr>
    </table>

    <button class="btn">Выгрузить таблицу</button>
  </div>
</template>

<script>
export default {
  name: "statistic-result",
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.table-header {
  .col {
    background-color: #000000b8;
    color: white;
  }
}
.col {
  border: 1px solid gray;
  text-align: center;
}
.btn {
  width: 100%;
  margin-top: 20px;
  background-color: #4285f4;
}
</style>
