<template>
  <div>
    <Statistic />
    <StatisticResult />
  </div>
</template>

<script>
import Statistic from "../components/MainComponent/Statistic";
import StatisticResult from "../components/MainComponent/StatisticResult";
export default {
  name: "main-page",
  components: {
    Statistic,
    StatisticResult
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/main.scss";

h1 {
  display: block;
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}
</style>
