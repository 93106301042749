const HTTP_WAY = "/api/v2/rpc";
const HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json"
};

const Method = {
  GET: `GET`,
  POST: `POST`,
  PUT: `PUT`,
  DELETE: `DELETE`
};

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    /* global M */
    M.toast({
      html: `[Ошибка]: ${response.status}: ${response.statusText}`,
      classes: "red darken-3",
      displayLength: 10000
    });
    return response;
  }
};

const API = class {
  getStatistic(formData) {
    return this._load({
      body: JSON.stringify({
        jsonrpc: "2.0",
        method: "get_stat",
        params: {
          project: formData.project,
          partner: formData.partner,
          datefrom: formData.datefrom,
          dateto: formData.dateto
        },
        id: 1
      })
    });
  }

  getReferences() {
    return this._load({
      body: JSON.stringify({
        jsonrpc: "2.0",
        method: "get_references",
        params: {},
        id: 1
      })
    });
  }

  /////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////

  _load({
    httpWay = HTTP_WAY,
    method = Method.POST,
    body = null,
    headers = new Headers(HEADERS)
  }) {
    try {
      return fetch(httpWay, { method, body, headers })
        .then(checkStatus)
        .then(resp => resp.json())
        .catch(err => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }
};

export default API;
